create-tab-comment-form,
create-forum-comment-form {
    display: flex;
    flex-direction: column;

    .start-button-view,
    .editor-view,
    .preview-view {
        display: flex;
        flex-direction: column;

        .control-buttons {
            display: flex;
            flex-direction: row;
            gap: 4px;
        }
    }
}