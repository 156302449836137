#forum-post-page-wrapper {
    padding: 12px;
    width: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    align-items: center;

    #forum-post-max-width-wrapper {
        width: 100%;
        max-width: 800px;

        .header {
            .title {
                margin-bottom: 0;
            }
        }

        .content {

        }

        #forum-post-add-comment {
            
        }
    }
}