.forum-comment {
    width: calc(100% - 16px);
    padding: 2px 8px;
    border-top: 1px solid var(--border-color);

    .header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .content {
        width: calc(100% - 16px);
        margin-left: 16px;

        .markdown {
            margin-top: 0;
        }
    }
}